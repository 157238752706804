export default {
  home: '/',
  askAngel: {
    base: '/ask_angel',
    show: (id: string) => `/ask_angel/${id}`,
  },
  dashboard: (slug: string) => `/company/${slug}`,
  faq: '/faq',
  banking: {
    overview: '/banking',
    partners: '/banking/partners',
    cards: '/banking/cards',
    cardDetails: (id: string) => `/banking/cards/${id}`,
    newPhysicalCard: '/banking/cards/physical/new',
    newVirtualCard: '/banking/cards/virtual/new',
    chargeCard: {
      partners: '/banking/charge_cards/partners',
    },
    transaction: (accountId: string, transactionId: string) =>
      `/banking/accounts/${accountId}/transactions/${transactionId}`,
    accounts: '/banking/accounts',
    accountDetail: (id: string) => `/banking/accounts/${id}`,
    transferFunds: '/banking/transfer_funds',
    statements: '/banking/statements',
    settings: '/banking/settings',
    international: {
      base: '/banking/international',
      accountDetail: (currencySlug: string) =>
        `/banking/international/accounts/${currencySlug}`,
      paymentDetail: (paymentId: string) =>
        `/banking/international/payments/${paymentId}`,
    },
    crypto: {
      base: '/banking/crypto',
      kyc: '/banking/crypto/kyc',
      depositAccountDetail: (currencySlug: string) =>
        `/banking/crypto/deposit_accounts/${currencySlug}`,
    },
  },
  analytics: {
    base: '/analytics',
  },
  liquidityPrograms: {
    base: '/liquidity_programs',
    show: (id: string, tab?: string) =>
      `/liquidity_programs/${id}${tab ? `?tab=${tab}` : ''}`,
  },
  stakeholderPortal: {
    base: '/stakeholder_portal',
    settings: '/stakeholder_portal/settings',
    stakeholders: {
      base: '/stakeholder_portal/stakeholders',
      show: (id: string) => `/stakeholder_portal/stakeholders/${id}`,
    },
  },
  stakeholders: {
    base: '/stakeholders',
    manageGroups: '/stakeholders/manage_groups',
    consents: {
      base: '/stakeholders/consents',
      show: (id: string, tab?: string) =>
        `/stakeholders/consents/${id}${tab ? `?tab=${tab}` : ''}`,
    },
    portal: {
      base: '/stakeholders/portal',
      settings: '/stakeholders/portal/settings',
      stakeholders: {
        base: '/stakeholders/portal/stakeholders',
        show: (id: string) => `/stakeholders/portal/stakeholders/${id}`,
      },
    },
    offers: {
      base: '/stakeholders/offers',
      new: '/stakeholders/offers?new=true',
      marketPay: `/stakeholders/offers/compensation-benchmarking`,
      configuration: '/stakeholders/offers/configuration',
      show: (id: string, tab?: string) =>
        `/stakeholders/offers/${id}${tab ? `?tab=${tab}` : ''}`,
      offerSections: {
        new: '/stakeholders/offers/configuration/offer-page-sections/new',
        show: (id: string) =>
          `/stakeholders/offers/configuration/offer-page-sections/${id}`,
      },
    },
    boardConsents: {
      all: '/stakeholders/board_consents',
    },
  },

  comply: {
    base: '/comply',
    valuations_409a: {
      base: '/comply/valuations_409a',
      newRequest: '/comply/valuations_409a/valuation-quote',
    },
    iso_nso: '/comply/iso_nso',
    tax_withholding: '/comply/tax_withholding',
    form_3921: '/comply/form_3921',
    asc_718: '/comply/asc_718',
  },
  raise: {
    rounds: '/raise',
    newRound: '/raise?new=true',
    roundsOverview: '/raise/rounds',
    roundDetail: (id: string, tab: string = 'investors', pane?: string) =>
      `/raise/rounds/${id}?tab=${tab}${pane ? `&pane=${pane}` : ''}`,
    marketInsights: '/raise/market_insights',
    advisors: '/raise/advisors',
    advisor: (id: string) => `/raise/advisors/${id}`,
    investors: {
      update: (id: string) => `/raise/investors/updates/${id}`,
      updates: '/raise/investors/updates',
      sendUpdate: (id: string) => `/raise/investors/updates/${id}/send`,
      templates: '/raise/investors/templates',
      equityDrops: '/raise/investors/equity_drops',
      newEquityDrop: '/raise/investors/equity_drops/new',
      equityDrop: (id: string) => `/raise/investors/equity_drops/${id}`,
    },
    dataRooms: {
      base: '/raise/data-rooms/all',
      new: '/raise/data-rooms/new',
      view: (id: string) => `/raise/data-rooms/${id}`,
      dataRoomFile: (id: string) => `/raise/data-rooms/data-room-file/${id}`,
    },
  },
  company: {
    companyDetails: '/company',
    yourTasks: '/company/your_tasks',
    permissions: '/company/permissions',
    perks: '/company/perks',
    billing: '/company/billing',
    signatories: '/company/signatories',
    integrations: '/company/integrations',
    emailLogs: '/company/email_logs',
    bankDetails: {
      all: '/company/bank_details',
      new: '/company/bank_details/new',
      edit: (id: string) => `/company/bank_details/${id}/edit`,
      view: (id: string) => `/company/bank_details/${id}`,
      partners: '/company/bank_details/partners',
    },
  },
  payments: {
    base: (angelFee: number, otherFee: number, roundId: string) =>
      `/pay-angellist?angel_fee=${angelFee}&other_fee=${otherFee}&round_id=${roundId}`,
  },
  equity: {
    base: '/equity',
    setup: '/equity/setup',
    shareClasses: '/equity/share_classes',
    newShareClasses: '/equity/share_classes?new=true',
    shareClass: (id: string, tab?: string) =>
      `/equity/share_classes/${id}${tab ? `?tab=${tab}` : ''}`,
    shareCertificates: '/equity/shares',
    newShareCertificate: '/equity/shares?new=true',
    workflows: {
      base: '/equity/workflows',
      stockSplits: {
        show: (id: string, tab?: string) =>
          `/equity/workflows/stock_splits/${id}${tab ? `?tab=${tab}` : ''}`,
      },
    },
    capTableAccess: {
      base: '/equity/cap_table_access',
    },
    accelerationTemplates: {
      all: '/equity/acceleration_templates',
    },
    shareCertificate: (id: string, tab?: string) =>
      `/equity/shares/${id}${tab ? `?tab=${tab}` : ''}`,
    stakeholders: {
      base: '/equity/stakeholders',
      show: (id: string) => `/equity/stakeholders/${id}`,
      bulkDocuments: '/equity/stakeholders/bulk_documents',
    },
    equityBlocks: {
      base: '/equity/equity_blocks',
      show: (id: string, tab?: string) =>
        `/equity/equity_blocks/${id}${tab ? `?tab=${tab}` : ''}`,
    },
    convertibles: '/equity/convertibles',
    drafts: {
      all: '/equity/drafts',
      shares: {
        all: `/equity/drafts?tab=shares`,
      },
      options: {
        all: `/equity/drafts?tab=options`,
        show: (id: string) => `/equity/drafts/options/${id}`,
      },
      convertibles: {
        all: `/equity/drafts?tab=convertibles`,
        safe: (id: string, tab?: string) =>
          `/equity/drafts/convertibles/safes/${id}${tab ? `?tab=${tab}` : ''}`,
        debt: (id: string, tab?: string) =>
          `/equity/drafts/convertibles/debt/${id}${tab ? `?tab=${tab}` : ''}`,
        warrant: (id: string) => `/equity/drafts/convertibles/warrants/${id}`,
        tokenWarrant: (id: string) =>
          `/equity/drafts/convertibles/token_warrants/${id}`,
        saft: (id: string) => `/equity/drafts/convertibles/safts/${id}`,
      },
    },
    convertibleDebt: (id: string, tab?: string) =>
      `/equity/convertibles/debt/${id}${tab ? `?tab=${tab}` : ''}`,
    safe: (id: string, tab?: string) =>
      `/equity/convertibles/safes/${id}${tab ? `?tab=${tab}` : ''}`,
    warrant: (id: string) => `/equity/warrants/${id}`,
    tokenWarrant: (id: string) =>
      `/equity/crypto_instruments/token_warrants/${id}`,
    newTokenWarrant: '/equity/crypto_instruments/token_warrants/new',
    saft: (id: string) => `/equity/crypto_instruments/safts/${id}`,
    newSaft: '/equity/crypto_instruments/safts/new',
    rollups: '/equity/safe_cleanups',
    valuations: {
      all: '/equity/valuations',
      show: (id: string) => `/equity/valuations/${id}`,
      newRequest: '/equity/valuations/valuation-quote',
    },
    reports: {
      all: '/equity/reports',
      asc718Report: '/equity/reports/asc718/',
    },
    vestingTemplates: {
      all: '/equity/vesting_templates',
      new: '/equity/vesting_templates?new=true',
      view: (id: string, tab?: string) =>
        `/equity/vesting_templates/${id}${tab ? `?tab=${tab}` : ''}`,
    },
    simulators: {
      index: '/equity/simulations',
      convertibles: '/equity/simulations/convertibles',
      exitAnalysis: '/equity/simulations/exit-analysis',
    },
    transactions: '/equity/transactions',
    buyBack: {
      new: '/equity/transactions/buybacks/new',
      show: (id: string) => `/equity/transactions/buybacks/${id}`,
      eligibilities: {
        new: (buybackId: string) =>
          `/equity/transactions/buybacks/${buybackId}/new`,
      },
    },
    exercise: (id: string) => `/equity/transactions/exercises/${id}`,
    amendment: (id: string) => `/equity/transactions/amendments/${id}`,
    grants: {
      base: '/equity/grants',
      draftGrant: '/equity/grants?draft=true',
      esopGrant: (id: string, tab?: string) =>
        `/equity/grants/esop_grants/${id}${tab ? `?tab=${tab}` : ''}`,
      rsaGrant: (id: string, tab?: string) =>
        `/equity/grants/rsa_grants/${id}${tab ? `?tab=${tab}` : ''}`,
    },
    rsuGrants: {
      base: '/equity/rsu_grants',
      show: (id: string, tab?: string) =>
        `/equity/rsu_grants/${id}${tab ? `?tab=${tab}` : ''}`,
    },
    tender_offers: { index: '/equity/tender_offers' },
    plans: {
      base: '/equity/plans',
      equityPlan: (id: string) => `/equity/plans/${id}`,
      equityPlanDetailTab: (id: string) => `/equity/plans/${id}?tab=details`,
      equityPlanOptionTab: (id: string) => `/equity/plans/${id}?tab=options`,
      equityPlanRsasTab: (id: string) => `/equity/plans/${id}?tab=rsas`,
      equityPlanAmendmentTab: (id: string) =>
        `/equity/plans/${id}?tab=amendments`,
      equityPlanEquityBlocksTab: (id: string) =>
        `/equity/plans/${id}?tab=equity_blocks`,
      newEquityIncentivePlan: '/equity/plans/new_equity_incentive_plan',
    },
    bulkEsign: {
      all: '/equity/bulk_esigns',
      new: '/equity/bulk_esigns/new',
      show: (bulkEsignId: String) => `/equity/bulk_esigns/${bulkEsignId}`,
    },
    grantDocument: {
      all: '/equity/grant_templates',
      grantDocumentSet: (id: string, tab?: string) =>
        `/equity/grant_templates/${id}${tab ? `?tab=${tab}` : ''}`,
    },
    healthChecks: {
      base: '/equity/health_checks',
      history: '/equity/health_checks?tab=history',
      aiReview: (tab: string = 'inconsistencies') =>
        `/equity/health_checks/ai_review?tab=${tab}`,
      checkTypeDetail: (checkType: string) =>
        `/equity/health_checks/${checkType}`,
    },
    companyLegends: {
      all: '/equity/legends',
      view: (id: string) => `/equity/legends/${id}`,
    },
    bulkEsignGrants: {
      base: '/equity/grants/bulk_company_esign_grants',
    },
  },
  offers: {
    base: '/offers',
    new: '/offers?new=true',
    marketPay: `/offers/compensation-benchmarking`,
    configuration: '/offers/configuration',
    show: (id: string, tab?: string) =>
      `/offers/${id}${tab ? `?tab=${tab}` : ''}`,
    offerSections: {
      new: '/offers/configuration/offer-page-sections/new',
      show: (id: string) => `/offers/configuration/offer-page-sections/${id}`,
    },
  },
  legal: {
    primaryCounsel: '/legal',
    documentImport: '/legal/document_import',
    companyDocuments: '/legal/documents',
    documentTemplates: {
      all: '/legal/document-templates',
      show: (id: string) => `/legal/document-templates/${id}`,
      new: '/legal/document-templates?new=true',
    },
    boardMembers: '/legal/board_members',
    boardConsents: {
      all: '/legal/board_consents',
      show: (id: string) => `/legal/board_consents/${id}`,
    },
    generalCounsel: '/legal/general_counsel',
    insurance: '/legal/insurance',
    filings: {
      base: '/legal/filings',
      certificateOfGoodStandings: {
        all: '/legal/filings/certificate_of_good_standings',
        new: '/legal/filings/certificate_of_good_standings/new',
      },
      certificateOfIncorporations: {
        all: '/legal/filings/certificate_of_incorporations',
        new: '/legal/filings/certificate_of_incorporations/new',
      },
      registeredAgentSubscriptions: {
        all: '/legal/filings/registered_agent_subscriptions',
        new: '/legal/filings/registered_agent_subscriptions/new',
      },
      foreignQualifications: {
        all: '/legal/filings/foreign-qualifications',
        new: '/legal/filings/foreign-qualifications/new',
      },
      '83b': {
        all: '/legal/filings/83b',
        old: '/legal/filings/83b/old',
        new: '/legal/filings/83b/new',
        newForSpa: (spaId: string) => `/legal/filings/spa/${spaId}/83b/new`,
        show: (id: string) => `/legal/filings/83b/${id}`,
      },
    },
  },
};
